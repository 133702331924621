<template>
  <div class="materials">
    <header>
      <div class="search">
        <UIcon name="search" />
        <input type="text" v-model="search" placeholder="Поиск по материалам" />
      </div>
    </header>

    <div class="list">
      <router-link
        to="/material/0"
        class="material-card create"
      >
        <feather-icon
          size="35"
          icon="PlusCircleIcon"
          class="icon"
        />
        <div class="title">Добавить материал</div>
      </router-link>

      <MaterialCard v-for="(material, index) of materials" :key="index" :material="material" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MaterialCard from "@/components/redesign/materials/MaterialsCard";

export default {
  name: 'Materials',
  components: { MaterialCard },
    data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapState('courses', ['materials']),
  },
  methods: {
    ...mapActions('courses', ['getMaterial']),
  },
  async mounted() {
    await this.getMaterial();
  },
};
</script>

<style lang="scss">
.materials {
  width: 100%;

  header {
    margin-bottom: 24px;

    .search {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 48px;
      background: #FFFFFF;
      border-radius: 8px;
      width: 100%;

      .icon {
        fill: #A9A9A9;
        margin-right: 24px;
      }

      input {
        height: 100%;
        padding: 0;
        border: none;
        font-size: 16px;
      }
    }
  }

  .list {
    display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;

    .material-card {
      width: 100%;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 12px 13px rgba(197, 197, 197, 0.25);
      }
      
      &.create {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 16px;
        font-weight: 500;

        .title {
          margin-top: 10px;
          color: var(--primary);
        }

        .icon {
          color: var(--primary);
        }
      }
    }
  }
}
</style>