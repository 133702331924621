import { render, staticRenderFns } from "./MaterialsCard.vue?vue&type=template&id=ae9f48ba&"
import script from "./MaterialsCard.vue?vue&type=script&lang=js&"
export * from "./MaterialsCard.vue?vue&type=script&lang=js&"
import style0 from "./MaterialsCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports