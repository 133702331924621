<template>
    <div class="material-card">
      <div class="block" @click="chose">
        <div class="tags">
          <div v-for="(item, index) of tags" :key="index" class="tag">
          {{ item }}
          </div>
        </div>
        <div class="title">{{ material.title }}</div>
        <p>{{ description }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MaterialsCard',
    props: {
    material: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      description() {
        let description = this.material.description;
        if (description.length > 10) {
          description = `${description.slice(0, 75)}...`;
        }
        return description;
      },
      tags() {
        return this.material.tags ? JSON.parse(this.material.tags) : [];
      },
    },
    methods: {
      chose() {
        this.$router.replace(`/material/${this.material.id}`)
      }
    }
  }
  </script>
  
  <style lang="scss">
  .material-card {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid var(--support-silver);
    border-radius: 15px;
  
    .block {
      width: 100%;
      height: 100%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
  
      .section {
        background: #0088cc1f;
        color: #0088cc;
        border-radius: 10px;
        padding: 5px 10px;
      }
  
      .title {
        font-size: 18px;
        display: flex;
        align-items: center;
        color: var(--gray-dark);
        font-weight: 600;
      }
    }

    .tags {
      display: flex;

      .tag {
        padding: 1px 8px;
        background: #DFEDF4;
        border-radius: 6px;
        cursor: pointer;
        color: #0088CC;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
        margin-bottom: 6px;
      }
    }
  
  
    .delete {
      display: flex;
      align-items: center;
      height: 100%;
      width: 40px;
  
      .icon {
        fill: var(--text-silver);
        width: 24px;
        height: 24px;
  
        &:hover {
          fill: var(--error);
        }
      }
    }
  }
  </style>